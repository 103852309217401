import axios from '/src/plugins/axios';
import {
  DELETE_CONTRACT_INFO,
  GET_CONTRACT_INFO,
  INSERT_CONTRACT_INFO,
  LOAD_CONTRACT_INFO,
  UPDATE_CONTRACT_INFO,
  GET_CONTRACT_PRODUCT,
  LOAD_CONTRACT_PRODUCT,
  DELETE_CONTRACT_PRODUCT,
  UPDATE_CONTRACT_PRODUCT,
} from '../type';

export default {
  [GET_CONTRACT_INFO](context) {
    axios({
      url: '/contracts',
      method: 'get',
    }).then(res => context.commit(LOAD_CONTRACT_INFO, res.data));
  },
  [DELETE_CONTRACT_INFO](context, payload) {
    for (let i of payload) {
      axios({
        url: '/contracts/' + i['id'],
        method: 'delete',
      });
    }
  },
  [INSERT_CONTRACT_INFO](context, payload) {
    axios({
      url: '/contracts',
      method: 'post',
      data: payload,
    });
  },
  [UPDATE_CONTRACT_INFO](context, payload) {
    axios({
      url: '/contracts/' + payload['id'],
      method: 'patch',
      data: payload,
    });
  },
  [GET_CONTRACT_PRODUCT](context, payload) {
    axios({
      url: '/contracts/' + payload['id'] + '?join=products',
    }).then(res => {
      if (res.data.products[0] === undefined) {
        context.commit(LOAD_CONTRACT_PRODUCT, { product: {} });
        return;
      }
      axios({
        url: '/contract-products/' + res.data.products[0].id,
      }).then(res => {
        context.commit(LOAD_CONTRACT_PRODUCT, res.data);
      });
    });
  },
  [DELETE_CONTRACT_PRODUCT](context, payload) {
    axios({
      url: '/contract-products/' + payload['id'],
      method: 'delete',
    });
  },
  [UPDATE_CONTRACT_PRODUCT](context, payload) {
    // 注意这里并不是单纯的更新，因为新建一个合同之后这个合同是没有产品的，那么product里也就什么都没有
    // 因此第一次给合同加产品并不是更新，而是增加....
    if (payload['id'] === undefined) {
      axios({
        url: '/contract-products',
        method: 'post',
        data: payload,
      });
      return;
    }
    axios({
      url: '/contract-products/' + payload['id'],
      method: 'patch',
      data: payload,
    });
  },
};
