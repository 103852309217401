import { LOAD_CONTRACT_INFO, LOAD_CONTRACT_PRODUCT } from '../type';

export default {
  [LOAD_CONTRACT_INFO](state, res) {
    state.contract_info = res;
  },
  [LOAD_CONTRACT_PRODUCT](state, res) {
    state.contract_product = res;
  },
};
