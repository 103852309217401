import Vue from 'vue';
import axios from 'axios';
import router from '../router';
import { showMsg } from '../util/msg';

//请求拦截器
axios.interceptors.request.use(
  function (config) {
    //从localStorage里取出token
    const token = localStorage.getItem('tokenName');
    //把token塞入Authorization里
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  },
);

//响应拦截器
axios.interceptors.response.use(
  function (response) {
    //获取更新的token
    const { authorization } = response.headers;
    //如果token存在则存在localStorage
    authorization && localStorage.setItem('tokenName', authorization);
    return response;
  },
  function (error) {
    if (error.response) {
      const { status } = error.response;
      //如果401或405则到登录页
      if (status === 405) {
        showMsg('error', '登陆过期，请重新登陆！', false, 1000);
        setTimeout(() => router.push('/login'), 1000);
      }
      if (status === 401) {
        showMsg('error', '用户名或密码错误!', false);
      }
      if (status === 404 || status === 500 || status === 400) {
        Vue.prototype.$message({
          duration: 1000,
          type: 'error',
          message: '服务器错误！',
        });
      }
    }
    return Promise.reject(error);
  },
);

axios.defaults.baseURL = '/api/';

Vue.prototype.axios = axios;

export default axios;
