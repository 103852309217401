import actions from './actions';
import mutations from './mutations';

export default {
  state: {
    contract_info: [],
    contract_product: {},
  },
  actions,
  mutations,
};
