export const PersonInfo = {
  component: () => import('../components/person/Info'),
  path: '/person/info',
};

export const Person = {
  component: () => import('../views/Person'),
  path: '/person',
  children: [PersonInfo],
};
