import {
  GET_CUSTOMER_INFO,
  LOAD_CUSTOMER_INFO,
  DELETE_CUSTOMER_INFO,
  INSERT_CUSTOMER_INFO,
  UPDATE_CUSTOMER_INFO,
} from '../type';
import axios from '/src/plugins/axios';

// 用AXIOS的时候配置了baseURL，这导致跨域请求失败，不清楚为什么...
// 可能是因为vue.config里的？大概就是不用设置baseURL的情况就不会报错，包括
// 不设置之后直接请求/api/xx
export default {
  [GET_CUSTOMER_INFO](context, payload) {
    axios({
      url: payload.url,
      method: payload.method,
    }).then(res => context.commit(LOAD_CUSTOMER_INFO, res['data']));
  },
  [DELETE_CUSTOMER_INFO](context, payload) {
    // 这里先不通过action的方式调用删除，因为这个操作似乎不是针对仓库而言的，而是
    // 针对数据库而言的，所以没必要？
    payload.forEach(val => {
      axios({
        url: '/customers/' + val['id'],
        method: 'delete',
      });
    });
  },
  [UPDATE_CUSTOMER_INFO](context, payload) {
    axios({
      url: '/customers/' + payload['id'],
      method: 'patch',
      data: payload,
    });
  },
  [INSERT_CUSTOMER_INFO](context, payload) {
    axios({
      url: '/customers',
      method: 'post',
      data: payload,
    });
  },
};
