const DashboardContacts = {
  component: () => import('../components/contacts/ContactContainer'),
  path: '/dashboard/contacts',
};
//仪表盘
const DashboardWorkbench = {
  component: () => import('../components/workbench/workbench'),
  path: '/dashboard/workbench',
};
//线索
const DashboardLeads = {
  component: () => import('../components/leads/leads'),
  path: '/dashboard/leads',
};
//待办事项
const DashboardMessage = {
  component: () => import('../components/message/message'),
  path: '/dashboard/message',
};

const DashboardCustomer = {
  component: () => import('../components/customer/CustomerContainer'),
  path: '/dashboard/customer',
};

const DashboardStuff = {
  component: () => import('../components/staff/StaffContainer'),
  path: '/dashboard/staff',
};

const DashboardDept = {
  component: () => import('../components/dept/DeptContainer'),
  path: '/dashboard/dept',
};

const DashboardPosition = {
  component: () => import('../components/position/PositionContainer'),
  path: '/dashboard/position',
};

const DashboardProduct = {
  component: () => import('../components/product/ProductContainer'),
  path: '/dashboard/product',
};

const DashboardContact = {
  component: () => import('../components/contact/ContactContainer'),
  path: '/dashboard/contact',
};

const DashboardContract = {
  component: () => import('../components/contract/ContractContainer'),
  path: '/dashboard/contract',
};

const DashboardReceivable = {
  component: () => import('../components/receivable/ReceivableContainer'),
  path: '/dashboard/receivable',
};

export const Dashboard = {
  component: () => import('../views/Dashboard'),
  path: '/dashboard/:id',
  children: [
    DashboardContacts,
    DashboardCustomer,
    DashboardStuff,
    DashboardDept,
    DashboardPosition,
    DashboardProduct,
    DashboardContact,
    DashboardContract,
    DashboardWorkbench,
    DashboardLeads,
    DashboardMessage,
    DashboardReceivable,
  ],
};
