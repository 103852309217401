import Vue from 'vue';
import VueRouter from 'vue-router';
import { Dashboard } from './dashboard';
import { Person } from './person';

Vue.use(VueRouter);

const Login = {
  component: () => import('../views/Login'),
  path: '/login',
};

const Apitest = {
  component: () => import('../views/Apitest'),
  path: '/apitest',
};

const routes = [Login, Dashboard, Person, Apitest];

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.beforeEach((to, from, next) => {
  const isLogin = window.sessionStorage.getItem('login');
  if (to.path === '/login') next();
  else if (isLogin !== 'yes') next('/login');
  else next();
});

export default router;
