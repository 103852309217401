export const GET_CUSTOMER_INFO = 'getCustomerInfo';
export const LOAD_CUSTOMER_INFO = 'loadCustomerInfo';
export const DELETE_CUSTOMER_INFO = 'deleteCustomerInfo';
export const UPDATE_CUSTOMER_INFO = 'updateCustomerInfo';
export const INSERT_CUSTOMER_INFO = 'insertCustomerInfo';

export const GET_STAFF_INFO = 'getStaffInfo';
export const LOAD_STAFF_INFO = 'loadStaffInfo';
export const INSERT_STAFF_INFO = 'insertStaffInfo';
export const DELETE_STAFF_INFO = 'deleteStaffInfo';
export const UPDATE_STAFF_INFO = 'updateStaffInfo';

export const GET_POSITION_INFO = 'getPositionInfo';
export const LOAD_POSITION_INFO = 'loadPositionInfo';
export const INSERT_POSITION_INFO = 'insertPositionInfo';
export const UPDATE_POSITION_INFO = 'updatePositionInfo';
export const DELETE_POSITION_INFO = 'deletePositionInfo';

export const GET_DEPT_INFO = 'getDeptInfo';
export const LOAD_DEPT_INFO = 'loadDeptInfo';
export const INSERT_DEPT_INFO = 'insertDeptInfo';
export const UPDATE_DEPT_INFO = 'updateDeptInfo';
export const DELETE_DEPT_INFO = 'deleteDeptInfo';

export const GET_PRODUCT_INFO = 'getProductInfo';
export const LOAD_PRODUCT_INFO = 'loadProductInfo';
export const DELETE_PRODUCT_INFO = 'deleteProductInfo';
export const INSERT_PRODUCT_INFO = 'insertProductInfo';
export const UPDATE_PRODUCT_INFO = 'updateProductInfo';

export const GET_CONTACT_INFO = 'getContactInfo';
export const LOAD_CONTACT_INFO = 'loadContactInfo';
export const DELETE_CONTACT_INFO = 'deleteContactInfo';
export const INSERT_CONTACT_INFO = 'insertContactInfo';
export const UPDATE_CONTACT_INFO = 'updateContactInfo';

export const GET_CONTRACT_INFO = 'getContractInfo';
export const GET_CONTRACT_PRODUCT = 'getContractInfoById';
export const LOAD_CONTRACT_INFO = 'loadContractInfo';
export const LOAD_CONTRACT_PRODUCT = 'loadContractProduct';
export const DELETE_CONTRACT_INFO = 'deleteContractInfo';
export const DELETE_CONTRACT_PRODUCT = 'deleteContractProduct';
export const INSERT_CONTRACT_INFO = 'insertContractInfo';
export const UPDATE_CONTRACT_PRODUCT = 'updateContractProduct';
export const UPDATE_CONTRACT_INFO = 'updateContractInfo';

export const GET_RECEIVABLE_INFO = 'getReceivableInfo';
export const LOAD_RECEIVABLE_INFO = 'loadReceivableInfo';
export const DELETE_RECEIVABLE_INFO = 'deleteReceivableInfo';
export const UPDATE_RECEIVABLE_INFO = 'updateReceivableInfo';
export const INSERT_RECEIVABLE_INFO = 'insertReceivableInfo';
