import {
  DELETE_PRODUCT_INFO,
  GET_PRODUCT_INFO,
  INSERT_PRODUCT_INFO,
  LOAD_PRODUCT_INFO,
  UPDATE_PRODUCT_INFO,
} from '../type';
import axios from '/src/plugins/axios';

export default {
  [GET_PRODUCT_INFO](context) {
    axios({
      url: '/products',
      method: 'get',
    }).then(res => context.commit(LOAD_PRODUCT_INFO, res.data));
  },
  [DELETE_PRODUCT_INFO](context, payload) {
    for (let i of payload) {
      axios({
        url: '/products/' + i['id'],
        method: 'delete',
      });
    }
  },
  [UPDATE_PRODUCT_INFO](context, payload) {
    axios({
      url: '/products/' + payload['id'],
      method: 'patch',
      data: payload,
    });
  },
  [INSERT_PRODUCT_INFO](context, payload) {
    axios({
      url: '/products',
      method: 'post',
      data: payload,
    });
  },
};
