import {
  GET_STAFF_INFO,
  LOAD_STAFF_INFO,
  INSERT_STAFF_INFO,
  UPDATE_STAFF_INFO,
  DELETE_STAFF_INFO,
} from '../type';
import axios from '/src/plugins/axios';

export default {
  [GET_STAFF_INFO](context, payload) {
    axios({
      url: payload.url,
      method: payload.method,
    }).then(res => context.commit(LOAD_STAFF_INFO, res.data));
  },
  [INSERT_STAFF_INFO](context, payload) {
    axios({
      method: 'post',
      url: '/employees',
      data: payload,
    });
  },
  [UPDATE_STAFF_INFO](context, payload) {
    axios({
      url: '/employees/' + payload['id'],
      method: 'put',
      data: payload,
    });
  },
  [DELETE_STAFF_INFO](context, payload) {
    payload.forEach(val => {
      axios({
        url: '/employees/' + val['id'],
        method: 'delete',
      });
    });
  },
};
