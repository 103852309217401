import Vue from 'vue';
import Vuex from 'vuex';
import customer_store from './customer';
import staff_store from './staff';
import dept_store from './dept';
import position_store from './position';
import product_store from './product';
import contact_store from './contact';
import contract_store from './contract';
import receivable_store from './receivable';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {},
  mutations: {},
  actions: {},
  modules: {
    customer_store,
    staff_store,
    dept_store,
    position_store,
    product_store,
    contact_store,
    contract_store,
    receivable_store,
  },
});
