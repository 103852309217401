import axios from '/src/plugins/axios';
import {
  DELETE_RECEIVABLE_INFO,
  GET_RECEIVABLE_INFO,
  INSERT_RECEIVABLE_INFO,
  LOAD_RECEIVABLE_INFO,
  UPDATE_RECEIVABLE_INFO,
} from '../type';
export default {
  [GET_RECEIVABLE_INFO](context) {
    axios({
      url: '/receivables',
      method: 'get',
    }).then(res => context.commit(LOAD_RECEIVABLE_INFO, res.data));
  },
  [DELETE_RECEIVABLE_INFO](context, payload) {
    for (let i of payload) {
      axios({
        url: '/receivables/' + i['id'],
        method: 'delete',
      });
    }
  },
  [UPDATE_RECEIVABLE_INFO](context, payload) {
    axios({
      url: '/receivables/' + payload['id'],
      method: 'patch',
      data: payload,
    });
  },
  [INSERT_RECEIVABLE_INFO](context, payload) {
    axios({
      url: '/receivables',
      method: 'post',
      data: payload,
    });
  },
};
