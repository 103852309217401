import {
  DELETE_DEPT_INFO,
  GET_DEPT_INFO,
  INSERT_DEPT_INFO,
  LOAD_DEPT_INFO,
  UPDATE_DEPT_INFO,
} from '../type';
import axios from '/src/plugins/axios';

export default {
  [GET_DEPT_INFO](context) {
    axios({
      method: 'get',
      url: '/depts',
    }).then(res => context.commit(LOAD_DEPT_INFO, res.data));
  },
  [INSERT_DEPT_INFO](context, payload) {
    axios({
      method: 'post',
      url: '/depts',
      data: payload,
    });
  },
  [UPDATE_DEPT_INFO](context, payload) {
    axios({
      method: 'patch',
      url: '/depts/' + payload['id'],
      data: { name: payload['name'] },
    });
  },
  [DELETE_DEPT_INFO](context, payload) {
    for (let i of payload) {
      axios({
        method: 'delete',
        url: '/depts/' + i['id'],
      });
    }
  },
};
